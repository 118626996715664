@import "~bootstrap/scss/bootstrap";
@import "sweetalert2/dist/sweetalert2.min.css";

:root {
	--bs-blue: #0d6efd;
	--bs-indigo: #6610f2;
	--bs-purple: #6f42c1;
	--bs-pink: #d63384;
	--bs-red: #dc3545;
	--bs-orange: #fd7e14;
	--bs-yellow: #ffc107;
	--bs-green: #198754;
	--bs-teal: #20c997;
	--bs-cyan: #0dcaf0;
	--bs-black: #000;
	--bs-white: #fff;
	--bs-gray: #6c757d;
	--bs-gray-dark: #343a40;
	--bs-gray-100: #f8f9fa;
	--bs-gray-200: #e9ecef;
	--bs-gray-300: #dee2e6;
	--bs-gray-400: #ced4da;
	--bs-gray-500: #adb5bd;
	--bs-gray-600: #6c757d;
	--bs-gray-700: #495057;
	--bs-gray-800: #343a40;
	--bs-gray-900: #212529;
	--bs-primary: #0d6efd;
	--bs-secondary: #6c757d;
	--bs-success: #198754;
	--bs-info: #0dcaf0;
	--bs-warning: #ffc107;
	--bs-danger: #dc3545;
	--bs-light: #f8f9fa;
	--bs-dark: #212529;
	--bs-primary-rgb: 13, 110, 253;
	--bs-secondary-rgb: 108, 117, 125;
	--bs-success-rgb: 25, 135, 84;
	--bs-info-rgb: 13, 202, 240;
	--bs-warning-rgb: 255, 193, 7;
	--bs-danger-rgb: 220, 53, 69;
	--bs-light-rgb: 248, 249, 250;
	--bs-dark-rgb: 33, 37, 41;
	--bs-white-rgb: 255, 255, 255;
	--bs-black-rgb: 0, 0, 0;
	--bs-body-color-rgb: 33, 37, 41;
	--bs-body-bg-rgb: 255, 255, 255;
	--bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
		"Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
		"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
		"Noto Color Emoji";
	--bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
		"Liberation Mono", "Courier New", monospace;
	--bs-gradient: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0.15),
		rgba(255, 255, 255, 0)
	);
	--bs-body-font-family: var(--bs-font-sans-serif);
	--bs-body-font-size: 1rem;
	--bs-body-font-weight: 400;
	--bs-body-line-height: 1.5;
	--bs-body-color: #212529;
	--bs-body-bg: #fff;
	--bs-border-width: 1px;
	--bs-border-style: solid;
	--bs-border-color: #dee2e6;
	--bs-border-color-translucent: rgba(0, 0, 0, 0.175);
	--bs-border-radius: 0.375rem;
	--bs-border-radius-sm: 0.25rem;
	--bs-border-radius-lg: 0.5rem;
	--bs-border-radius-xl: 1rem;
	--bs-border-radius-2xl: 2rem;
	--bs-border-radius-pill: 50rem;
	--bs-link-color: #0d6efd;
	--bs-link-hover-color: #0a58ca;
	--bs-code-color: #d63384;
	--bs-highlight-bg: #fff3cd;
}

.login-container {
	width: 100%;
	max-width: 533px;
	box-shadow: 0 0 20px rgb(0 0 0 / 14%);
}

.registration-container {
	width: 100%;
	max-width: 800px;
}

.text-bg-dark a:not(.btn),
.text-bg-dark button.btn-link,
.text-bg-dark button.nav-link {
	color: var(--bs-cyan);
}

.text-bg-dark .form-floating {
	color: var(--bs-gray-dark);
}

.form-control:disabled,
.form-select:disabled {
	background-color: var(--bs-gray-500);
}

.d-flex .toast {
	z-index: 1000;
}

/*
	slide-fade transition
*/
// .slide-fade-enter-active {
// 	transition: all 0.3s ease-out;
// }

// .slide-fade-leave-active {
// 	transition: all 0.7s cubic-bezier(1, 0.5, 0.8, 1);
// }

// .slide-fade-enter-from,
// .slide-fade-leave-to {
// 	transform: translateX(20px);
// 	opacity: 0;
// }

.list-enter-active,
.list-leave-active {
	transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
	opacity: 0;
	transform: translateX(30px);
}

/*
	fade transition
*/
.fade-leave-active {
	transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

/*
	sweetalert2
*/
.swal2-footer {
	font-size: 0.75em;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

.text-bg-dark .page-item:not(.active) .page-link {
	background-color: inherit;
}
.text-bg-dark .page-link.active,
.text-bg-dark .active > .page-link {
	border-color: var(--bs-cyan);
}
.text-bg-dark .page-link.disabled a {
	color: var(--bs-pagination-disabled-color);
}

#termsModal h1 {
	font-size: 1.5rem;
}
#termsModal h2 {
	font-size: 1rem;
}

/* some stylings for the autocomplete layer */
.vue-tags-input .ti-new-tag-input {
	background: transparent;
	color: #000;
}
.vue-tags-input .ti-autocomplete {
	background-color: var(--bs-dark) !important;
	border: 1px solid #8b9396;
	border-top: none;
	max-height: 212px;
	overflow: hidden;
	overflow-y: auto;
}
